import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@pages/HomePage.vue'),
  },
  {
    path: '/justmove',
    name: 'just-move',
    component: () => import('@pages/JustMove.vue'),
  },
]

export default routes
