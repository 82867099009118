import { Plugin } from 'vue'
import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'

function loadLocaleMessages(): { [x: string]: LocaleMessages<VueMessageType> } {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages: { [x: string]: LocaleMessages<VueMessageType> } = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  locale: navigator.languages ? navigator.languages[0] : navigator.language,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})

export const i18nPlugin = {
  install: app => {
    app.use(i18n)
  },
} as Plugin

export default i18n
