<template>
  <router-view />
</template>

<style lang="scss">
@import '@style/app.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: get-color(lightHighlight);
}
</style>
