
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    asset(): string {
      return require(`@assets/${this.name}.svg`)
    },
    assetInline(): string {
      return require(`@assets/${this.name}.svg?inline`)
    },
  },
})
