
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: null,
    },
  },
  computed: {
    asset1(): string {
      return require(`@assets/${this.name}.png`)
    },
    asset2(): string {
      return require(`@assets/${this.name}@2x.png`)
    },
    srcset(): string {
      return `${this.asset1} 1x, ${this.asset2} 2x`
    },
  },
})
