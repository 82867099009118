import { Plugin } from 'vue'

// function importAll(context: __WebpackModuleApi.RequireContext) {
//   context.keys().forEach(context)
// }

// importAll(require.context('.', true, /\.ts$/))

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  install: app => {},
} as Plugin
