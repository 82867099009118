import { createApp } from 'vue'
import App from './App.vue'
import { routerPlugin } from '@router'
import { storePlugin } from '@store'
import { i18nPlugin } from '@lang'
import components from '@components/common'
import layouts from '@layouts'
import plugins from '@plugins'

createApp(App)
  .use(storePlugin)
  .use(routerPlugin)
  .use(i18nPlugin)
  .use(components)
  .use(layouts)
  .use(plugins)
  .mount('#app')
