import { Plugin } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export const routerPlugin = {
  install: app => {
    app
      .use(router)
      .use(createMetaManager())
      .use(metaPlugin)
  },
} as Plugin

export default router
