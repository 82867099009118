// Globally register all common components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import { Plugin } from 'vue'
import loadComponents from '@utils/load-components'

const context = require.context(
  // Look for files in the current directory
  '.',
  // Look also in subdirectories
  true,
  // Only include .vue files
  /\.vue$/
)

export default {
  install: app => {
    loadComponents(app, context)
  },
} as Plugin
