import { Plugin } from 'vue'
import { createStore } from 'vuex'

const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {},
})

export const storePlugin = {
  install: app => {
    app.use(store)
  },
} as Plugin

export default store
