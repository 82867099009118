import { App } from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export default function(
  app: App,
  context: __WebpackModuleApi.RequireContext
): void {
  // For each matching file name...
  context.keys().forEach(fileName => {
    // Get the component config
    const componentConfig = context(fileName)

    // Get the PascalCase version of the component name
    const componentName = upperFirst(
      camelCase(
        fileName
          // Remove the "./_" from the beginning
          .replace(/^\.\/_/, '')
          // Remove the file extension from the end
          .replace(/\.\w+$/, '')
          // Remove all but the name
          .replace(/^.*\//, '')
      )
    )
    // Globally register the component
    app.component(componentName, componentConfig.default || componentConfig)
  })
}
