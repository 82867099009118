<template>
  <div :class="$style.container">
    Hello World!
  </div>
</template>

<style module lang="scss">
.container {
  background-color: white;
}
</style>
