<template>
  <!-- eslint-disable vue/no-v-html -->
  <div v-if="inline" :class="$style.container" v-html="assetInline"></div>
  <!--eslint-enable vue/no-v-html -->
  <img v-else :src="asset" :alt="alt ?? name" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    asset(): string {
      return require(`@assets/${this.name}.svg`)
    },
    assetInline(): string {
      return require(`@assets/${this.name}.svg?inline`)
    },
  },
})
</script>

<style module lang="scss">
.container {
  line-height: 0;
}

.container svg {
  width: 100%;
  height: 100%;
}
</style>
